<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 30%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <!--            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 30%">-->
            <!--              <a-range-picker v-model="queryInstallDate"></a-range-picker>-->
            <!--            </a-form-model-item>-->
            <div class="query-btns" style="float: right;width: 30%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 0px -10px 0px -10px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="streamstarttime" slot-scope="value, record">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="streamduration" slot-scope="value">
            <span>{{value?timeHandle(value):''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.streamid">详情</a-menu-item>
                <a-menu-item :key="'delete-'+record.streamid">删除</a-menu-item>
                <!--                <a-menu-item :key="'edit-'+record.netreportid">修改</a-menu-item>-->
                <!--                <a-menu-item :key="'delete-'+record.netreportid">修改</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>

<script>
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import {mapState} from "vuex";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import {deleteAbnormalStream, getAbnormalStreamListByCondition} from "A/monitoring";
import moment from "moment";
import {deleteBasemap} from "A/patrol";

export default {
  name: "abnormalStream",
  mixins: [deptselect, pagination],
  data() {
    return {
      moment,
      queryParams: {
        monitorpointname: '',
        starttime: '',
        endtime: '',
      },
      userdepidCascaderSelected:[],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备ID',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
        },
        {
          title: '码流开始时间',
          dataIndex: 'streamstarttime',
          key: 'streamstarttime',
          ellipsis: true,
          scopedSlots: {customRender: 'streamstarttime'},
        },
        {
          title: '码流总时长',
          dataIndex: 'streamduration',
          key: 'streamduration',
          ellipsis: true,
          scopedSlots: {customRender: 'streamduration'},
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '异常码流';
    },
    breadcrumb() {
      return [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.queryParams.userdepsid = val[val.length - 1];
      } else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
      this.initDeptOptionsAll();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getAbnormalStreamListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let streamid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'streamid', streamid);
      if(type == 'detail') {
      }else if(type=='delete'){
        this.deleteConfirm(record)
      }
    },
    deleteConfirm(record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.streamid);
      }).catch(()=>{
      });
    },
    delete(streamid) {
      this.showLoading();
      if (streamid) {
        let params = {
          streamid
        };
        deleteAbnormalStream(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else{
        returnTime=time + "秒";
      }
      return returnTime
    },
  }
}
</script>

<style scoped>

</style>